import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-368a39ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid m-0 justify-content-center" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "flex justify-content-end align-items-center my-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SalesDataWrapper = _resolveComponent("SalesDataWrapper")!
  const _component_SalesDialog = _resolveComponent("SalesDialog")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Footer = _resolveComponent("Footer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createVNode(_component_Button, {
            class: "split-sales-order-btn",
            icon: "pi pi-filter-slash",
            outlined: "",
            onClick: _ctx.clearFilters
          }, null, 8, ["onClick"]), [
            [_directive_tooltip, 'Clear All Filters']
          ]),
          _createVNode(_component_Button, {
            class: "split-sales-order-btn",
            label: "Print/Export",
            icon: "pi pi-print",
            onClick: _ctx.printExportData
          }, null, 8, ["onClick"]),
          _createVNode(_component_Button, {
            label: "New Order",
            icon: "pi pi-plus",
            "data-test": "newSalesOrder",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSales(false)))
          })
        ])
      ])
    ]),
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_SalesDataWrapper, {
          ref: "salesTable",
          "customer-index": _ctx.index,
          "current-view": _ctx.currentView,
          onUpdateBadge: _cache[1] || (_cache[1] = 
          (field, value) => _ctx.$emit('update-badge', field, value)
        )
        }, null, 8, ["customer-index", "current-view"]),
        _createVNode(_component_SalesDialog, {
          order: this.salesOrder,
          show: this.showSalesDialog,
          readOnly: this.readOnly,
          showLoadExistingQuoteBtn: this.showLoadExistingQuoteBtn,
          customer: _ctx.getCustomerFields,
          onHide: _cache[2] || (_cache[2] = 
          (hidden) => {
            this.salesOrder = null;
            this.showSalesDialog = hidden;
          }
        ),
          onOnSave: _ctx.fetchSales
        }, null, 8, ["order", "show", "readOnly", "showLoadExistingQuoteBtn", "customer", "onOnSave"])
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ], 64))
}