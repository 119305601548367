
import { defineComponent } from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import SalesOrder from "@/types/salesorder";
import Button from "primevue/button";
import Card from "primevue/card";
import SalesDialog from "@/components/Sales/SalesDialog.vue";
import SalesDataWrapper from "@/components/Sales/SalesDataWrapper.vue";
import Footer from "@/components/Footer.vue";
export default defineComponent({
  name: "Sales",
  components: {
    SalesDataWrapper,
    Button,
    SalesDialog,
    Card,
    Footer,
  },
  props: {
    index: Number,
    currentView: String,
  },
  data() {
    return {
      items: [] as Array<SalesOrder>,
      selectedSalesID: null,
      selectedCustomer: null,
      selectedSale: null,
      selectedRow: null,
      order: false,
      orderRecordsBy: "",
      selectedSalesStatus: null,
      readOnly: true,
      sortOrder: 1,
      sortField: "",
      first: 0,
      rows: 10,
      showLoadExistingQuoteBtn: false,
      page: 1,
      isLoadingSales: false,
      status: [
        { status: "New", initial: "N" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" },
      ],
      showSalesDialog: false,
      salesOrder: null as unknown,
    };
  },
  computed: {
    ...mapGetters({
      getOrders: "sales/getOrders",
      getRangeEnd: "sales/getRangeEnd",
      getFirstRow: "sales/getFirstRow",
      getClient: "sales/getClient",
      getCustomer: "customerInquiry/getCustomer",
    }),
    getCustomerFields(): any {
      const {
        cust_id,
        terms,
        resale,
        code,
        name,
        contact_id_items,
        bill_to,
        disc,
      } = this.getCustomer(this.index);
      return {
        cust_id,
        terms,
        resale,
        code,
        name,
        contact_id_items,
        bill_to,
        disc,
      };
    },
  },
  mounted() {
    this.first = this.getFirstRow;
    (this.$refs.salesTable as any).fetchOrdersData(false);
  },
  methods: {
    ...mapActions({
      setFirstRow: "sales/setFirstRow",
      fetchOrdersRecords: "sales/fetchOrdersRecords",
      pushSalesId: "customerInquiry/pushSalesId",
    }),
    printExportData() {
      (this.$refs as any).salesTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesTable.clearAllFiltersAndFetch();
    },
    openSales(edit: boolean) {
      this.showSalesDialog = true;
      this.readOnly = edit;
      this.showLoadExistingQuoteBtn = true;
    },

    fetchFilteredOrders(addOrder: boolean) {
      this.isLoadingSales = true;
      this.fetchOrdersRecords({
        custs: [this.selectedCustomer?.["cust_id"]],
        id: this.selectedSalesID,
        status: this.selectedSalesStatus,
        client: this.getClient,
        addOrder: addOrder,
        correls: "cust_name",
      })
        .then((resp) => {
          this.items = resp.data;
        })
        .catch(() => {
          this.isLoadingSales = false;
        })
        .finally(() => {
          this.isLoadingSales = false;
        });
    },
    fetchSales(sale: any) {
      if (this.isLoadingSales == true) {
        this.isLoadingSales = false;
      }
      this.isLoadingSales = true;
      this.pushSalesId({
        index: this.index,
        id: sale.so_id,
      });
      (this.$refs.salesTable as any).fetchOrdersData(false);
    },
  },
});
